/*@import "./styles/scroll-bar.css";*/
body > * {
	font-family: "Arial";
}
/* Variables */

/*
#005aa1
#5f5f64
#f08300
#dfa439
#008fc8
#006a6c (e
*/
:root {
	--color-primary: #03367f;

	--color-primary-light: #008fc8;
	--color-primary-dark: #03367f;

	--ant-collapse-header: 80px;
	--time-animationP: 0.5s;
	--time-animationH: 0.3s;
	--icon-size-large: 28px;

	--font-size-large: 28px;
	--font-size-medium: 18px;
	--font-size-small: 16px;
	--font-size-tiny: 14px;

	--palette-blak: #000 !important;
	--palette-white: #fff !important;
	--palette-gray: rgba(235, 235, 235) !important;
	--palette-gray-1: rgb(200, 200, 200) !important;
	--palette-gray-2: rgba(150, 150, 150) !important;
	--palette-gray-3: rgba(100, 100, 100) !important;
	--palette-gray-4: rgba(70, 70, 70) !important;

	/* Light Variables */
	--palette-yellow: #f9e090 !important;
	--palette-beige: #ff935c !important;
	--palette-orange: #dc5353 !important;
	--palette-purple: #a72461 !important;

	--palette-green: #006a6c !important;

	--palette-blue-dark: #032b4e !important;
	/* Dark Variables */
	--palette-gray-darken: #5f5f64 !important;
	--palette-gray-darken-1: #516680 !important;
	--palette-gray-darken-2: #3d4d61 !important;
	--palette-gray-darken-3: #2f3c4c !important;
	--palette-gray-darken-4: #263342 !important;
}
.text-primary {
	color: var(--color-primary) !important;
}
.bg-primary {
	background-color: var(--color-primary);
}
.bg-blue-dark {
	background-color: var(--palette-blue-dark);
}

/* Custom Buttons */
.btn-green,
.btn-green:hover,
.btn-green:focus {
	background-color: var(--palette-green) !important;
	color: var(--palette-white) !important;
	border-color: var(--palette-green) !important;
	font-weight: bold !important;
	font-family: Arial, Helvetica, sans-serif;
}
.btn-gray,
.btn-gray:hover,
.btn-gray:focus {
	background-color: var(--palette-gray) !important;
	color: var(--palette-gray-4) !important;
	border-color: var(--palette-gray) !important;
	font-weight: bold !important;
}
.h-100 {
	height: 100vh !important;
	width: 100% !important;
	background: red;
}
/* .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--palette-gray-4) !important;
  box-shadow: -1px 0 0 0 rgba(250, 173, 20, 0.2) !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.65) !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: rgba(0, 0, 0, 0.25) !important;
} */
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	box-shadow: none !important;
}
.ant-page-header {
	padding: 24px 0px 0px 0px !important;
	background: transparent !important;
}
.card-item {
	transition: all 0.2s ease-in-out;
}
.card-item:hover {
	transform: translateY(-0.5rem);
}
.App {
	text-align: center;
}
.title-inline h2 {
	font-size: 30px;
}
.title-inline > * {
	display: inline;
}
.title-inline i {
	display: inline;
	padding: 0px 5px;
	font-size: 20px;
	vertical-align: middle;
}
.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.main-content > div {
	background-image: url("./resources/images/patron-01.png") !important;
	background-color: #fff;
	min-height: 100vh;
}
.layout-container {
	background-image: url("./resources/images/patron-01.png") !important;
}
.main-content > div::before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	/* background-color: #fff; */
}
.table-small .ant-table-column-title,
.inner-table .ant-table-column-title {
	font-size: 12px !important;
}
.bg-main-container {
	background-image: url("./resources/images/patron-01.png") !important;
	/* background-image: url("./resources/images/patron-01.png"); */
	/*  background-size: 100% 100%; */
	/*  background-repeat: no-repeat; */
	/* height: 100vh; */
	min-height: 86vh;
	width: 100%;
	/* opacity: .4; */
	margin: 0px !important;
	position: relative;
}

.active-user-decorator{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.active-user-decorator::after{
	content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: chartreuse;
    display: block;
    position: relative;
    top: -2px;
    margin-right: 7px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
