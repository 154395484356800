.perfil-form-container {
	background: #fff !important;
	padding: 10px;
	min-height: calc(80vh - 50px);
}
.perfil-container {
	/*  background: #fff !important; */
	padding: 10px;
	min-height: calc(80vh - 50px);
}
.perfil-toolbar {
	padding: 10px;
	margin: 10px 0px !important;
	/* background: #fff !important; */
}
.perfil-container .ant-tabs-nav .ant-tabs-tab {
	padding: 0px 8px !important;
}
.contact-container {
	background: #fff !important;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 1px 1px 4px 4px #cccccc2b;
	margin: 10px;
}
