.page-preloading {
	text-align: center;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	margin-bottom: 20px;
	padding: 30px 50px;
	/* margin: 20px 0; */
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.preloading {
	height: 100vh;
	width: 100%;
	background-image: url("../../../resources/images/patron-01.png");
	margin: 0px !important;
	position: absolute;
	left: 0;
	top: 0;
}
.preloading::before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0.85);
	opacity: 0.85;
}
.preloading-container {
	width: 200px;
	height: 200px;
	z-index: 999;
}
.preloading-container .ant-spin-spinning {
	padding-top: 50px;
}
.preloading-container i {
	background-color: var(--color-primary);
}
.preloading-container a {
	font-size: 16px;
	font-weight: bold;
	color: var(--color-secondary);
	font-family: "Arial, Open Sans";
}
