/*Card List File*/

.file-card-list {
	width: 1020px;
}

.file-card-list .card-cover {
	background-position: center;
	background-size: cover;
}
.file-card-list .card-cover-container {
	overflow: hidden;
	height: 200px;
}
.file-card-list .card-title > * {
	display: inline;
}
.file-card-list .card-title i {
	vertical-align: middle;
	padding: 0px 5px;
	font-size: 18px;
}
.file-card-list .card-title {
	font-weight: bold;
	color: var(--color-primary);
}
.file-card-list .ant-card-body {
	padding: 0.1px !important;
}
.file-card-list .card-title h2 {
	margin-bottom: 0px !important;
	font-size: 12px;
	font-weight: 600;
	color: var(--color-primary);
}
.file-card-list .ant-card-meta-description {
	padding: 0px 5px;
	line-height: 16px;
}
.file-card-list .card-cover {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	-webkit-mask-image: -webkit-radial-gradient(center, white, black);
	transition: transform 0.4s;
}
.file-card-list .card-cover:hover {
	transform: scale(1.2);
}
.file-card-list .card-item {
	border-radius: 10px !important;
	overflow: hidden;
}
/* .file-card-list {
	background-position: center;
	background-size: cover;
	overflow: hidden;
	height: 200px;
	display: inline;
	font-weight: bold;
	color: var(--color-primary);
	margin-bottom: 0px !important;
	font-size: 12px;
	font-weight: 600;
	color: var(--color-primary);
	padding: 0px 5px;
	line-height: 16px;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	-webkit-mask-image: -webkit-radial-gradient(center, white, black);
	transition: transform 0.4s;
	transform: scale(1.2);
	border-radius: 10px !important;
	overflow: hidden;
	background-position: center;
	background-size: cover;
	padding: 0.1px !important;
}

.file-card-list i {
	vertical-align: middle;
	padding: 0px 5px;
	font-size: 18px;
} */

@media ( max-width: 864px ){
	.file-card-list {
		width: auto;
	}
} 