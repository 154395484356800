.afiliados-left {
	padding-top: 50px;
}
/* .afliliados-layout .form-search-container {
  max-width: calc(100% - 50px);
} */

.company-logo-container {
	text-align: center;
	height: 100px;
}
.card-item-afiliados .cover-container {
	height: 80px;
	width: 100%;
	position: relative;
	padding: 20px 0px 10px 0px;
	/* hola */
}
.card-item-afiliados .company-logo {
	width: 100px;
	max-width: calc(100% - 10px);
	min-width: 100px;
	max-height: 100%;
}
.card-item-afiliados .afiliado-title-container {
	text-align: center;
	line-height: 14px;
	padding: 10px;
}
.card-item-afiliados .afiliado-title-container h2 {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 6px;
	max-width: 100%;
	/* overflow: hidden; */
	text-overflow: ellipsis;
}
/* Modal Info */
.title-company {
	line-height: 10px !important;
}
.title-company h2 {
	font-size: 14px;
	font-weight: bold;
}
.col-contact {
	display: block;
	width: 100%;
}
.col-contact.center {
	text-align: center !important;
}
.col-contact.left {
	text-align: left !important;
}
.ant-card-cover {
	text-align: center !important;
}
.avatar-icon {
	background: rgb(238, 237, 237) !important;
	border: 2px solid #fff !important;
	box-shadow: 1px 1px 2px 4px #e7e7e71c !important;
	height: 150px !important;
	width: 150px !important;
}
.avatar-icon svg {
	color: #a7a7a76b !important;
	font-size: 100px !important;
	padding-top: 20px;
}
