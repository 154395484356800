.tab-detail-land .ant-tabs-tab {
	padding: 0px 15px !important;
}
.tab-detail-land .ant-tabs-ink-bar {
	height: 0px !important;
}

.tab-detail-land .ant-tabs-nav {
	padding: 0px !important;
	margin: 8px 0px;
}
.tab-detail-land .ant-tabs-nav h2 {
	margin-bottom: 0px;
}
.tab-detail-land .ant-tabs-tab {
	padding: 8px 20px !important;
	border-radius: 5px;
	background: var(--palette-gray) !important;
}
.tab-detail-land .ant-tabs-tab-active.ant-tabs-tab {
	background: var(--palette-green) !important;
}
.tab-detail-land .ant-tabs-tab-active h2 {
	color: #fff !important;
}
.tab-detail-land .ant-tabs-tab h2 {
	font-size: 16px;
}
.modal-detail .item-list {
	padding-inline-start: 0px !important;
}
.modal-detail .item-list li {
	text-decoration: none !important;
	list-style: none;
}
.modal-detail .ant-modal-header {
	box-shadow: 0px 2px 4px 2px #cccccc75 !important;
	margin-bottom: 10px !important;
}
.modal-detail .ant-modal-body {
	margin: 0px 0px 20px 0px !important;
	padding: 0px 20px 10px 20px !important;
}
.tab-container {
	margin: 20px !important;
	min-height: 400px;
}
.detail-info,
.value-field {
	font-family: "Arial, Open Sans";
}
.value-field {
	font-size: 12px;
	/* font-weight: 600; */
}
.avatar-detail {
	box-shadow: 1px 1px 2px 2px #cccccc75;
	border: 1px solid #cccccc75;
	border-radius: 8px;
	width: 200px;
	height: 250px;
}
.modal-detail .ant-tabs-nav {
	width: 100% !important;
	display: flex;
	justify-content: center;
	justify-items: center;
}
.modal-detail .ant-tabs-tab {
	margin: 0px 8px auto;
	text-align: center;
}
.project-detail {
	margin: 20px;
	/*  min-height: 400px; */
}
.cover-project {
	border-radius: 10px;
	background-repeat: no-repeat !important;
	box-shadow: 0px 0px 0px 2px #cccccc75 !important;
	background-size: cover !important;
	background-position: center !important;
}
