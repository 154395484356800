.asesoria-layout {
	min-height: 100vh !important;
	padding: 0px;
	margin: 0px !important;
}
.asesoria-layout .land-container {
	margin: 0px;
}
.asesoria-layout .advanced-form {
	padding: 8px !important;
}
.asesoria-layout .advanced-form .ant-form-item {
	margin-bottom: 5px !important;
}
.col-left {
	/* background: rgb(1, 1, 129); */
}
.grid-table {
	padding: 0px 40px !important;
	font-family: Arial;
}
.grid-table h2 {
	font-family: Arial;
}
.table-head {
	background: blue;
	color: #fff;
	font-family: Arial;
	padding: 5px;
	font-weight: bold;
}
.grid-row {
	margin: 5px 0px;
	padding: 5px;
	background: #fff;
}
.radius-chart .recharts-wrapper {
	margin: 0px auto !important;
}
.row-left {
	text-align: left !important;
	margin: 0px auto !important;
}
.report-container {
	background: #fff;
	padding: 0px !important;
	min-height: 400px;
	border: 1px solid #ccc;
}
.title-head-report {
	font-weight: bold;
	width: 100%;
	background: rgb(1, 1, 129);
	color: #fff;
}
.box-yellow,
.box-blue,
.box-red {
	height: 100px;
	margin: 0px !important;
	color: #000;
	padding: 20px;
	margin: 0px auto;
	font-family: Arial;
	line-height: 16px;
	font-weight: bold;
}
.box-yellow {
	background: rgba(255, 255, 0, 0.397);
}
.box-blue {
	background: rgba(0, 0, 255, 0.397);
}
.box-red {
	background: rgba(255, 0, 0, 0.397);
}

/* Table */
.table-small {
	font-family: Arial;
}

.table-small .ant-table-footer {
	padding: 0px 8px !important;
	font-size: 14px !important;
}
.table-small .ant-table-small > .ant-table-content > .ant-table-body {
	padding: 0px 0px 5px 0px !important;
	margin: 10px !important;
}
.table-small .ant-table-thead,
.inner-table .ant-table-thead,
.inner-table .ant-table-thead > tr > th {
	background: rgb(25, 77, 140) !important;
}

.inner-table .ant-table-tbody > tr > td {
	margin: 0px !important;
	padding: 0px 5px !important;
}
.table-small .ant-table-column-title,
.inner-table .ant-table-column-title {
	font-size: 18px !important;
	color: #fff !important;
	font-weight: bold;
}
.ant-table-expanded-row .ant-table-column-title {
	font-size: 12px !important;
}
.table-small
	.ant-table-small
	> .ant-table-content
	> .ant-table-scroll
	> .ant-table-body
	> table
	> .ant-table-tbody
	> tr
	> td {
	padding: 0px !important;
	width: 20px !important;
}

.table-small
	.ant-table-small
	> .ant-table-content
	> .ant-table-body
	> table
	> .ant-table-tbody
	> tr
	> td,
.table-small
	.ant-table-small
	> .ant-table-content
	> .ant-table-scroll
	> .ant-table-body
	> table
	> .ant-table-tbody
	> tr
	> td {
	/* font-size: 0.6em !important; */
	font-size: 0.8rem !important;
	padding: 2px 5px !important;
}
.table-small .ant-table-thead th,
.inner-table .ant-table-thead th {
	padding: 0px 5px !important;
}
.inner-table {
	margin: 10px !important;
}
.table-without-title .ant-table-thead th[colspan="2"] {
	line-height: 0px !important;
	height: 0px !important;
	padding-top: 4px !important;
}
.table-small .ant-table-small > .ant-table-content > .ant-table-body {
	padding: 0px 0px 5px 0px !important;
	margin: 0px !important;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
	margin: 4px 0px !important;
}
