.main-layout {
	padding: 0px;
	margin: 0px;
	min-height: 100vh !important;
	background: #fafafa;
}
.main-content {
	margin: 0px;
	padding: 0px;
}
.logo {
	height: 32px !important;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px;
}

/* .main-container{
  background-color: #fafafa;
} */
