.form-search-container {
	background-color: var(--palette-white);
	border-radius: 10px;
	padding: 10px 0px;
}
.form-search-title {
	margin: 10px;
}
.form-search-title h2 {
	font-size: 18px !important;
}
.search-box .ant-input {
	background: #f5f5f5ab !important;
	border-radius: 20px;
	border: 1px solid #f5f5f5ab !important;
}
.field-wrapper label {
	/* padding-bottom: 5px !important; */
}
.field-wrapper .ant-select-selection {
	background: #f5f5f5ab !important;
	margin-top: 10px !important;
	min-width: 100px;
	border: 1px solid #f5f5f5ab !important;
}
.group-field label.btn-gray.ant-radio-button-wrapper::after {
	content: " ";
	width: 5px;
	height: 5px;
	/* background: #0e6a6b; */
	position: absolute;
	border: 2px solid #0e6a6b;
	border-radius: 50%;
	top: calc(50% - 5px);
	left: 8px;
	padding: 3px;
}
.group-field .ant-radio-button-wrapper:first-child,
.group-field .ant-radio-button-wrapper:last-child {
	margin: 0px 8px !important;
	border-radius: 8px !important;
	padding: 0px 20px !important;
	box-shadow: none !important;
}
.group-field .ant-radio-button-wrapper:not(:first-child)::before {
	content: none !important;
}
.btn-gray.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
	outline: none !important;
}
.group-field
	label.btn-gray.ant-radio-button-wrapper.ant-radio-button-wrapper-checked::after {
	content: " ";
	width: 4px;
	height: 4px;
	background: #0e6a6b;
	position: absolute;
	border: 2px solid #0e6a6b;
	border-radius: 50%;
	top: calc(50% - 4px);
	left: 8px;
	padding: 3px;
}
.group-field
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	padding-left: 15px;
}
