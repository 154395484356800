.login-form-container {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0px 0px 8px 8px #92929230;
	height: 100%;
}
.login-container {
	background-color: transparent !important;
	display: block;
	margin: 0px auto;
	width: 350px;
	height: 100%;
	min-height: 400px;
}
.login-form {
	height: 350px;
	width: 300px;
	margin-top: calc(50vh - 175px) !important;
	display: block;
}
.login-logo {
	width: 100px;
}
.login-logo-container {
	margin: 20px;
}
.ant-form-vertical .ant-form-item-label {
	padding: 0px !important;
}
.login-title {
	width: 100% !important;
}
.login-description {
	font-size: 12px;
	line-height: 14px;
	display: block;
}
.login-title span {
	display: inline;
}
.ant-form-item-label > label {
	color: var(--palette-gray-2) !important;
	font-size: 12px !important;
}
