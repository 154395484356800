.login-layout {
	padding: 0px;
	margin: 0px;
	height: 100vh !important;
	background: #fafafa;
}

.login-layout .ant-form-vertical .ant-form-item {
	margin-bottom: 0px !important;
}

.ant-form-vertical .ant-form-item {
	padding-bottom: 0px;
	/*  margin-bottom: 0px; */
}
.ant-col.ant-col-12.item-form {
	padding: 0px 18px !important;
}
.login-col-right::before {
	content: " ";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0.5;
	background-color: #fff;
}
.login-col-right,
.login-col-left {
	height: 100vh !important;
	overflow: hidden;
}
.login-col-right {
	background-image: url("../../resources/images/textura-01.png");
}
.login-col-left {
	background-image: url("../../resources/images/bg-login.jpg");
	background-position: center !important;
	background-repeat: no-repeat;
	background-size: cover;
}
.logo-login-container {
	padding: 0px;
}
.logo-login {
	width: 100px;
	height: 50px;
	margin: 20px;
}
.login-form {
	height: 400px;
	width: 400px;
	margin-top: calc(50vh - 200px) !important;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
/*xs 576px*/
@media (max-width: 576px) {
	.login-form-container .ant-row {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.login-col-left {
		height: 100vh !important;
	}
	.login-container {
		height: 100vh;
	}
	.login-form {
		height: 350px;
		width: calc(100% - 10px);
		margin-top: calc(50vh - 175px) !important;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.login-form-button {
		margin: 0px auto;
		font-size: 18px !important;
		font-weight: bold !important  ;
	}
	.login-col-right {
		background-image: none !important;
		position: absolute;
	}
	.login-col-right::before {
		opacity: 0;
	}
}
