$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color: white;
$input-background: white;

$dark-btn-background: #575057;
$light-btn-background: #d4d4d4;

$button-text-color: #fff;

$theme-color-1: #922c88;
$theme-color-2: #4556ac;
$theme-color-3: #af67a4;
$theme-color-4: #743c6e;
$theme-color-5: #4b5480;
$theme-color-6: #795d75;

$primary-color: #303030;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1: #a9449f;
$gradient-color-2: #3c164f;
$gradient-color-3: #8a3481;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: "/assets/img/logo-black.svg";
$logoPathMobile: "/assets/img/logo-mobile.svg";

$lpBackgroundHome: "/assets/img/landing-page/home-background-purple.png";
$lpBackgroundSub: "/assets/img/landing-page/sub-background-purple.png";
$lpBackgroundFooter: "/assets/img/landing-page/footer-background-purple.png";
$lpBackgroundEllipsisBottom: "/assets/img/landing-page/white-background-bottom.png";
$lpBackgroundEllipsisTop: "/assets/img/landing-page/white-background-top.png";

$lpLogoPath: "/assets/img/logo-white.svg";
$lpLogoPathPinned: "/assets/img/logo-black.svg";

@import "./_mixins.scss";
@import "./_gogo.style.scss";
