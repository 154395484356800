.contact-left,
.contact-right {
	/* background: red; */
	height: 100%;
}
.contact-container {
	color: var(--palette-gray-darken) !important;
	padding: 20px !important;
}
.contact-container i,
.contact-container .anticon {
	padding: 5px !important;
}
.contact-left .ant-row {
	line-height: 16px;
	margin-bottom: 10px;
}
.subtitle-contact {
	margin-bottom: 20px !important;
}
.contact-info-item .subtitle-contact i {
	font-size: 10px;
}
.contact-item-card > *,
.contact-info-item {
	color: var(--palette-gray-darken) !important;
	font-family: Arial;
}
.subtitle-contact h2,
.subtitle-contact i {
	color: var(--color-primary);
}
/* .contact-left {
  border-right: 1px solid #ccc;
} */
