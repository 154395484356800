.menu-light .ant-menu-horizontal {
	line-height: 30px !important;
	border-bottom: 0px !important;
}
.menu-light .ant-menu-item {
	border-bottom: 0px !important;
	padding: 0px 8px !important;
	/* font-weight: bold !important; */
}
.menu-light .ant-menu-item {
	border-radius: 6px;
	padding: 0px 20px !important;
}
.logo-header-container {
	display: inline !important;
}
.logo-header {
	max-width: 80px;
	height: 40px;
	width: 100%;
	object-fit: contain;
	object-position: center;
}
.menu-header .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #fff !important;
}
@media (max-width: 992px) {
	.menu-principal {
		width: 0px;
		position: absolute;
		left: 150px;
	}
}
/* .menu-header .ant-col {
  display: inline-block !important;
} */
