.home-layout {
	padding: 0px;
	margin: 0px;
	min-height: 100vh !important;
	background: #fafafa;
}
.home-content {
	margin: 10px 0px;
	padding: 0px;
}
h1.home-title {
	font-size: 30px;
}
.home-container {
	margin: 10% 0px;
	height: auto;
}

.land-container {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.footer-ligth {
	padding: 8px 50px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	background: transparent !important;
}
