.company-description {
	line-height: 16px !important;
	padding: 10px 0px !important;
	max-width: calc(100% - 5px);
	overflow: hidden;
	white-space: normal !important;
	text-overflow: ellipsis !important;
	margin-bottom: 0px !important;
	padding-bottom: 0px !important;
	height: 60px;
	font-size: 12px !important;
}
.company-info-container {
	padding: 20px 0px;
	font-family: "Arial, Open Sans";
	/*  color: var(--palette-green); */
}
.card-item-company-container .ant-card {
	border-radius: 10px !important;
}
.card-item-company-container .company-picture {
	width: 100% !important;
	height: 150px;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
}
.picture-company-container {
	margin: 10px 0px;
	padding: 10px;
}
.cover-container-company {
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid #ccc;
	overflow: hidden;
	width: calc(100% - 8px) !important;
}
.company-title {
	margin-bottom: 0px !important;
	font-size: var(--font-size-tiny);
	font-weight: bold;
	text-transform: capitalize;
	/* color: var(--palette-green) !important; */
}
.company-actions-container {
	margin: 10px !important;
}
.company-options {
	font-size: var(--font-size-tiny) !important;
}
.company-options i {
	font-size: 12px;
	color: var();
}
.company-name h2 {
	/* color: var(--palette-green); */
	font-size: var(--font-size-tiny);
	display: inline;
	font-weight: bold;
}
.title-compra {
	margin: 20px 10px;
}
.compra-left {
	padding-top: 50px;
}

@media (max-width: 992px) {
	.card-item-company-container .company-picture {
		height: 300px;
	}
}
