/* Cover */
.contact-cover {
	background-color: #ccc;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 400px;
	overflow: hidden;
}
.contact-content {
	padding-top: 2%;
}
.contact-layout {
	min-height: 100vh !important;
}
.contact-container.bg-main-container {
	height: 50vh !important;
}
@media (max-width: 1200px) {
	.contact-container.bg-main-container {
		height: 100% !important;
	}
	.contact-content {
		padding-top: 4%;
	}
}
