.header-home {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* max-height: 100px; */
	/*  margin-bottom: 20px; */
}
.header-home .ant-layout-header {
	/* height: auto !important; */
	padding: 0 8px !important;
}
.header-home .ant-menu-item .anticon,
.header-home .ant-menu-submenu-title .anticon {
	min-width: 16px !important;
	margin-right: 3px !important;
	font-size: 16px !important;
}
.header-home .ant-menu-horizontal {
	border-bottom: 0px !important;
}
.user-name {
	margin: 5px !important;
}
.menu-header-container {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	justify-items: center !important;
}
@media (max-width: 576px) {
	/* .header-home .menu-header {
    display: none;
  } */
}
